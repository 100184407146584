var API_BASE = 'https://s4biz-core.herokuapp.com/api/review';

export const isProduction = true;
export const isStaging = false;
export const isDemo = false;

// export const isProduction = REACT_APP_TARGET === 'production';
// export const isStaging = REACT_APP_TARGET === 'staging';
// export const isDemo = REACT_APP_TARGET === 'demo';

if (isProduction) {
    API_BASE = 'https://s4biz-prod.herokuapp.com/api/review';
} else if (isStaging) {
    API_BASE = 'https://s4biz-staging.herokuapp.com/api/review';
} else if (isDemo) {
    API_BASE = 'https://s4biz-demo-e31b21f80790.herokuapp.com/api/review';
} else {
    API_BASE = 'http://localhost:3001/api/review'
}

const {
    AUTH_TOKEN,
    LOGGING_COMBINED_FILE,
    LOGGING_ERROR_FILE,
    LOGGING_LEVEL,
    LOGGING_TYPE,
    NODE_ENV,
    SERVER_PORT,
} = process.env

const Config = {
    API_BASE: API_BASE,
    AUTH_TOKEN: AUTH_TOKEN,
    LOGGING: {
        COMBINED_FILE: LOGGING_COMBINED_FILE,
        ERROR_FILE: LOGGING_ERROR_FILE,
        LEVEL: LOGGING_LEVEL,
        TYPE: LOGGING_TYPE,
    },
    NODE_ENV,
    SERVER_PORT: parseInt(SERVER_PORT, 10),
}

export default Config
